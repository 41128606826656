import { yupResolver } from '@hookform/resolvers/yup';
import CardContainer from 'Components/Common/CardContainer';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import TitleInformation from 'Components/Page/Title/Copm/TitleInformation';
import VariationInformation from 'Components/Page/Title/Copm/VariationInformation';
import { useAppDispatch, useAppSelector } from 'App/Store';
import _, { isEmpty, omit } from 'lodash';
import useMessage from 'Hooks/useMessage';
import {
  arrayRequiredValidation,
  englishAndDashValidation,
  katakanaFullSizeValidation,
  maxMsg,
  numberRequired,
  stringRequired,
  stringRequiredTrim,
} from 'Utils/Validation';
import {
  setCurrentStep,
  setTitleForm,
  setTriggerMedia,
  setTriggerSaveDraft,
  setTriggerValidate,
} from 'App/Features/Title';
import {
  INCLUDE_COPY_RIGHT,
  INCLUDE_JARSAC_CONTENT,
  LINK_JMD_TYPE_CONTENT,
  msgJarsac,
  STEPS,
} from 'Constant/Title';
import ModalComponent from 'Components/Common/Modal';
import { TitleApi } from 'Datasource/TitleApi';
import { useHistory } from 'react-router-dom';
import {
  checkValidateSaveDraft,
  handleConvertTitleInfo,
  truncateText,
  variationFields,
} from 'Utils/Title';
import { getBool } from 'Utils/Permission';
import { Song, Variation, VariationFieldObj, Video } from 'Types/App/title';
import { MusicFormat } from 'Constant/Song';

const schema = yup.object().shape({
  name: yup.string().required('入力してください。').max(255, maxMsg(255)).trim().nullable(),
  furiganaName: katakanaFullSizeValidation({}).max(255, maxMsg(255)).trim().nullable(),
  label: stringRequired({}).nullable().max(100, maxMsg(100)).trim(),
  musicGenreId: numberRequired({}).nullable(),
  variations: yup.array().of(
    yup.object().shape({
      name: yup.string().when('type', {
        is: 1,
        then: stringRequired({}),
      }),
      type: numberRequired({}).nullable(),
      includeJasracContent: numberRequired({}).nullable(),
      musicFormat: numberRequired({}).nullable(),
      saleTokenDate: stringRequired({}).nullable(),
      notiDownloadTime: stringRequired({}).nullable(),
      downloadDate: stringRequired({}).nullable(),
      coverImageUrl: stringRequired({}).nullable(),
      productCode: englishAndDashValidation({}).max(13, maxMsg(13)).nullable(),
      janCode: stringRequired({}).nullable(),
      // booklets: arrayRequiredValidation({}).min(1, '入力してください。'),
      price: yup
        .string()
        .required('入力してください。')
        .test({
          name: 'price',
          message: '100,000円以下の値を入力してください。',
          test: (value, context: any) => {
            const { createError, from, path } = context;
            const { option } = from[0].value;
            const price = value ? +value : 0;
            const { minPrice } = option;
            if (price < +minPrice) {
              return createError({
                message: `${+minPrice}円以上を入力してください。`,
                path,
              });
            } else {
              return price < 100000;
            }
          },
        })
        .nullable(),
      songs: yup.array().of(
        yup.object().shape({
          name: stringRequiredTrim({}),
          sourceAudioUrl: stringRequiredTrim({})
            .test({
              name: 'sourceAudioUrl',
              message: '⾳源ファイルは の⾳源を登録してください。',
              test: (value, context: any) => {
                const { createError, from, path } = context;
                const { musicFormat } = from[1].value;
                const isWAV = (value || '')?.search('.wav') > 0;
                if (musicFormat === undefined || musicFormat === null) return true;
                const result =
                  (isWAV && musicFormat === MusicFormat.WAV) ||
                  (!isWAV && musicFormat === MusicFormat.MP3);
                return (
                  result ||
                  createError({
                    message: `⾳源ファイルは ${
                      musicFormat ? '.wav' : '.mp3'
                    } の⾳源を登録してください。`,
                    path,
                  })
                );
              },
            })
            .nullable(),
        }),
      ),
      videos: yup
        .array()
        .when(['songs'], {
          is: (songs: any) => songs.length == 0,
          then: arrayRequiredValidation({}).min(
            1,
            '楽曲コンテンツ、または動画コンテンツを入力してください。',
          ),
        })
        .of(
          yup.object().shape({
            name: stringRequired({}),
            introEc: stringRequired({}),
            includeCopyRightSong: numberRequired({}).nullable(),
            videoType: yup
              .number()
              .when('includeCopyRightSong', {
                is: INCLUDE_COPY_RIGHT.INCLUDE,
                then: numberRequired({}).nullable(),
              })
              .nullable(),
            songs: yup
              .array()
              .of(
                yup.object().shape({
                  name: stringRequiredTrim({}),
                }),
              )
              .when(['includeCopyRightSong'], {
                is: (includeCopyRightSong: number) =>
                  includeCopyRightSong === INCLUDE_COPY_RIGHT.INCLUDE,
                then: arrayRequiredValidation({}).min(1, '著作権管理楽曲を登録してください'),
              }),
          }),
        ),
      images: yup.array().of(
        yup.object().shape({
          name: stringRequired({}),
          introEc: stringRequired({}),
        }),
      ),
      riajGenreId: yup
        .number()
        .when(['videos', 'songs', 'jmdCooperation'], {
          is: (videos: any[], songs: any[], jmdCooperation: number) =>
            ((videos && videos.length > 0) || (songs && songs.length > 0)) &&
            jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
          then: numberRequired({}).nullable(),
        })
        .nullable(),
      jmdMusicGenreId: yup
        .number()
        .when(['songs', 'jmdCooperation'], {
          is: (songs: any[], jmdCooperation: number) =>
            songs && songs.length > 0 && jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
          then: numberRequired({}).nullable(),
        })
        .nullable(),
      jmdVideoGenreId: yup
        .number()
        .when(['videos', 'jmdCooperation'], {
          is: (videos: any[], jmdCooperation: number) =>
            videos && videos.length > 0 && jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
          then: numberRequired({}).nullable(),
        })
        .nullable(),
    }),
  ),
});

const Review = forwardRef((props: any): JSX.Element => {
  const {
    titleForm: { variations, id },
    titleForm,
    fetchTitleFail,
  } = useAppSelector((state) => state.title);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaveDraft, setIsSaveDraft] = useState<boolean>(false);
  const { openMessage } = useMessage();
  const dispatch = useAppDispatch();
  const [visableModal, setVisibleModal] = useState<boolean>(false);
  const { openMessageError } = useMessage();
  const history = useHistory();
  const variationRefs = useRef<any>([]);
  const titleFormRef = useRef<HTMLDivElement>(null);
  const [JPRecordingAssociationGenreOptions, setJPRecordingAssociationGenreOptions] = useState<
    { label: string; value: number }[]
  >([]);

  //* create ref to scroll field error
  const nameRef = useRef<any>([]);
  const musicFormatRef = useRef<any>([]);
  const saleTokenDateRef = useRef<any>([]);
  const downloadDateRef = useRef<any>([]);
  const notiDownloadTimeRef = useRef<any>([]);
  const productCodeRef = useRef<any>([]);
  const janCodeRef = useRef<any>([]);
  const priceRef = useRef<any>([]);
  const coverImageUrlRef = useRef<any>([]);
  const bookletsRef = useRef<any>([]);
  const songsRef = useRef<any>([]);
  const videosRef = useRef<any>([]);
  const imagesRef = useRef<any>([]);
  const includeJasracContentRef = useRef<any>([]);
  const jmdCooperationRef = useRef<any>([]);
  const riajGenreIdRef = useRef<any>([]);
  const jmdMusicGenreIdRef = useRef<any>([]);
  const jmdVideoGenreRef = useRef<any>([]);

  const {
    trigger,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      artistId: titleForm.artistId,
      name: titleForm.name,
      furiganaName: titleForm.furiganaName,
      type: titleForm.type,
      label: titleForm.label,
      musicGenreId: titleForm.musicGenreId,
      variations: titleForm.variations,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(titleForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleForm]);

  const validateForm = async () => {
    const isValidTitle = await trigger();

    return isValidTitle;
  };

  const handleValidateForm = async () => {
    setIsSaveDraft(false);
    if (_.isEmpty(titleForm.variations)) {
      openMessage({
        variant: 'error',
        message: '登録が失敗しました。',
      });
      return;
    }
    const isValid = await validateForm();

    // FIXME: handel validate jancode, products code all sytem
    const isValidCode = await handleValidateCode();

    if (isValid && isValidCode) {
      setVisibleModal(true);
      return;
    }
  };

  const handleValidateCode = async () => {
    setLoading(true);
    try {
      // FIXME: HAS CODE -> check duplicate producCode, janCode in status === 0
      const paramValidateCode = titleForm.variations.map((va: Variation, index: number) => {
        return {
          productCode: va.productCode,
          janCode: va.janCode,
          index,
          id: va?.id && va?.id?.toString()?.includes('uuid') ? null : va?.id,
        };
      });

      const lookupProductCode = paramValidateCode.reduce((a: any, e: any) => {
        a[e.productCode] = ++a[e.productCode] || 0;
        return a;
      }, {});
      const lookupJanCode = paramValidateCode.reduce((a: any, e: any) => {
        a[e.janCode] = ++a[e.janCode] || 0;
        return a;
      }, {});

      const errProductCode = paramValidateCode.filter((e: any) => lookupProductCode[e.productCode]);
      const errJanCode = paramValidateCode.filter((e: any) => lookupJanCode[e.janCode]);

      if (errProductCode.length > 0 || errJanCode.length > 0) {
        errProductCode.map((el: any) => {
          setError(`variations.${el?.index}.productCode`, {
            type: 'manual',
            message: '品番は既に存在しています。',
          });
        });
        errJanCode.map((el: any) => {
          setError(`variations.${el?.index}.janCode`, {
            type: 'manual',
            message: 'JANコードは既に存在しています。',
          });
        });
        return false;
      }
      //////////////////////////

      const {
        data: { data },
      } = await TitleApi.validateCode({
        variationValidationDtos: paramValidateCode,
        titleId: +id,
      });
      if (data?.length > 0) {
        data.map((el: any) => {
          if (Object.keys(el)[0] === 'janCode') {
            setError(`variations.${el?.index}.janCode`, {
              type: 'manual',
              message: 'JANコードは既に存在しています。',
            });
          } else {
            setError(`variations.${el?.index}.productCode`, {
              type: 'manual',
              message: '品番は既に存在しています。',
            });
          }
        });
      }
      return data.length === 0;
    } catch (err) {
      openMessageError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleScrollErrors = () => {
    if (typeof errors === 'object' && !isEmpty(errors)) {
      const errorsTitle = omit(errors, 'variations');
      const isErrorTitle = Object.values(errorsTitle).filter((el: any) => !!el).length > 0;
      if (isErrorTitle) {
        titleFormRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'center',
        });
      } else {
        if (errors?.variations && !isEmpty(errors.variations)) {
          const errorIndex = +Object.keys(errors.variations)[0] || 0;
          const formKeys = Object.keys(Object.values(errors.variations)[0] as any);

          const filterErrorMap = variationFields()
            .filter((item: VariationFieldObj) => formKeys.includes(item.name))
            .sort((a: VariationFieldObj, b: VariationFieldObj) => a.index - b.index);

          const errorKey: string = filterErrorMap[0]?.name || '';

          const formRefs: any = {
            name: nameRef,
            includeJasracContent: includeJasracContentRef,
            musicFormat: musicFormatRef,
            saleTokenDate: saleTokenDateRef,
            downloadDate: downloadDateRef,
            notiDownloadTime: notiDownloadTimeRef,
            productCode: productCodeRef,
            janCode: janCodeRef,
            price: priceRef,
            coverImageUrl: coverImageUrlRef,
            booklets: bookletsRef,
            songs: songsRef,
            videos: videosRef,
            images: imagesRef,
            jmdMusicGenreId: jmdMusicGenreIdRef,
          };

          if (filterErrorMap[0]?.name === 'songs') {
            dispatch(setTriggerMedia('songs'));
            return;
          }

          if (filterErrorMap[0]?.name === 'videos') {
            dispatch(setTriggerMedia('videos'));
            return;
          }

          if (filterErrorMap[0]?.name === 'images') {
            dispatch(setTriggerMedia('images'));
            return;
          }

          dispatch(setTriggerMedia(null));

          formRefs[errorKey as any]?.current[errorIndex]?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center',
          });
        }
      }
    }
  };

  const handleCreateAndUpdate = () => {
    if (!id) {
      handleCreateOfficalTitle();
      return;
    }
    handleUpdateOfficalTitle();
  };

  const handleCreateOfficalTitle = async () => {
    setVisibleModal(false);
    const params = { ...titleForm, name: titleForm?.name?.trim() };
    setLoading(true);
    try {
      const {
        data: { data },
      } = await TitleApi.createOfficalTitle(params);
      if (data?.id) history.push(`/title-detail/${data.id}`);
      const titleInfo = handleConvertTitleInfo(data);
      dispatch(setTitleForm({ ...titleInfo, isAuthor: 1 }));
      dispatch(setCurrentStep(STEPS.FINAL));
    } catch (err) {
      openMessageError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateOfficalTitle = async () => {
    setVisibleModal(false);
    const params = { ...titleForm, name: titleForm?.name?.trim() };
    setLoading(true);
    try {
      const {
        data: { data },
      } = await TitleApi.updateOfficalTitle({ id, params });
      const titleInfo = handleConvertTitleInfo(data);
      dispatch(setTitleForm(titleInfo));
      dispatch(setCurrentStep(STEPS.FINAL));
    } catch (err) {
      openMessageError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateDraft = async () => {
    const params = titleForm;
    setLoading(true);
    try {
      const {
        data: { data },
      } = await TitleApi.updateDraftTitle({ id, params });
      const titleInfo = handleConvertTitleInfo(data);
      dispatch(setTitleForm(titleInfo));
      openMessage({
        variant: 'success',
        message: '一時保存しました',
      });
    } catch (err) {
      openMessageError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveDraft = async () => {
    const params = titleForm;
    setLoading(true);
    try {
      const {
        data: { data },
      } = await TitleApi.createDraftTitle(params);
      if (data?.id) history.push(`/title-detail/${data.id}`);
      const titleInfoData = handleConvertTitleInfo(data);
      dispatch(setTitleForm({ ...titleInfoData, isAuthor: '1' }));
      dispatch(setCurrentStep(STEPS.CREATE_TITLE_INFO));
      const titleInfo = handleConvertTitleInfo(data);
      dispatch(setTitleForm(titleInfo));
      openMessage({
        variant: 'success',
        message: '一時保存しました',
      });
    } catch (err) {
      openMessageError(err);
    } finally {
      setLoading(false);
    }
  };
  const handleCheckPriceVariations = () => {
    let errorPrices: number[] = [];
    variations.forEach((variation: any, variationIndex: number) => {
      const firstPrice = variations[0]?.price ? +variations[0]?.price : 0;
      const variationPrice = variation?.price ? +variation?.price : 0;

      if (variation?.price) {
        if (variationPrice < firstPrice) {
          setError(`variations.${variationIndex}.price`, {
            type: 'manual',
            message: `${truncateText(titleForm?.artistName || '', 32)} / ${truncateText(
              titleForm?.name || '',
              32,
            )} / ${truncateText(variations[0]?.name || '', 32)} 以上の金額に設定してください。`,
          });
          errorPrices.push(variationIndex);
        } else {
          clearErrors(`variations.${variationIndex}.price`);
          errorPrices = errorPrices.filter((item: number) => item !== variationIndex);
        }
      }
    });

    if (errorPrices.length > 0) {
      priceRef.current[errorPrices[0]]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }

    return errorPrices.length > 0;
  };

  //* validate if includeJarsacContent === INCLUDE && songs must have at least 1 song with jarsac or nextone
  const checkAuthorizedGroupType = (): boolean => {
    const isPassed = variations.every((item: Variation) => {
      const songVideos = item?.videos?.flatMap((video: Video) => (video.songs ? video.songs : []));
      const songs = item?.songs ? item.songs : [];
      const songList = [...songVideos, ...songs];

      const songHasJarsacOrNextone = songList.filter(
        (song: Song) =>
          song.authorizedGroupType && [1, 2].includes(Number(song.authorizedGroupType)),
      );
      const isValid =
        (item.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE &&
          !isEmpty(songHasJarsacOrNextone) &&
          (!isEmpty(songs) || !isEmpty(songVideos))) ||
        item.includeJasracContent === INCLUDE_JARSAC_CONTENT.NOT_INCLUDE;

      return isValid;
    });
    return isPassed;
  };

  const handleValidateSaveDraft = async () => {
    setIsSaveDraft(true);
    await trigger(['artistId', 'name', 'label', 'furiganaName']);
    variations.map(async (variation: any, index: number) => {
      await trigger([
        `variations.${index}.name`,
        `variations.${index}.productCode`,
        `variations.${index}.janCode`,
        `variations.${index}.price`,
        `variations.${index}.saleTokenDate`,
        `variations.${index}.downloadDate`,
        `variations.${index}.songs`,
        `variations.${index}.videos`,
      ]);
    });

    const validateNextStep = await checkValidateSaveDraft(titleForm);
    dispatch(setTriggerSaveDraft(true));

    const isValidCode = await handleValidateCode();
    if (handleCheckPriceVariations()) return;

    if (!checkAuthorizedGroupType()) {
      openMessage({ variant: 'error', message: msgJarsac });
      return;
    }

    handleScrollErrors();
    if (validateNextStep.length === 0 && isValidCode) {
      titleForm.id ? handleUpdateDraft() : handleSaveDraft();
    }
  };

  const validateStepOld = () => {
    if (isSaveDraft) {
      dispatch(setTriggerSaveDraft(true));
    } else {
      dispatch(setTriggerValidate(true));
    }
  };

  const onClickTitleError = () => {
    validateStepOld();
    dispatch(setCurrentStep(STEPS.CREATE_TITLE_INFO));
  };

  const onClickVariationError = () => {
    validateStepOld();
    dispatch(setCurrentStep(STEPS.CREATE_VARIATION));
  };

  const handleBackStep = () => {
    dispatch(setCurrentStep(STEPS.CREATE_VARIATION));
  };

  const isDisableBtn = useMemo(() => {
    let disabled = false;
    if (!id) disabled = false;
    if (id && !getBool(titleForm?.isAuthor)) disabled = true;
    if (titleForm?.status) disabled = true;
    if (fetchTitleFail) disabled = true;
    return disabled;
  }, [fetchTitleFail, id, titleForm?.isAuthor, titleForm?.status]);

  useEffect(() => {
    handleScrollErrors();
  }, [errors]);

  const getListJPRecordingAssociation = async () => {
    const {
      data: { data },
    } = await TitleApi.getJPRecordingAssociationGenre();
    if (data.length > 0) {
      const result = data.map((item: { nameJp: string; id: number }) => {
        return { label: item.nameJp, value: item.id };
      });
      setJPRecordingAssociationGenreOptions(result);
    }
  };

  useEffect(() => {
    getListJPRecordingAssociation();
    //* reset trigger media when unmount
    return () => {
      dispatch(setTriggerMedia(null));
    };
  }, []);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Container className="mb-2" id="review">
        <CardContainer title="タイトル情報" darkHeader className="mb-3 mt-2">
          <Col className={`mx-4 mt-3 ${!titleForm?.id && 'd-none'}`}>
            <span>登録状況</span>

            <Button
              className={`btn me-2 h-35px mw-35 ms-4 ${!titleForm.status && 'btn-gradients'}`}
            >
              <p>{titleForm.status ? '登録済' : '一時保存'}</p>
            </Button>
          </Col>

          <div ref={titleFormRef}>
            <TitleInformation
              titleForm={titleForm}
              errors={errors}
              onClickError={onClickTitleError}
            />
          </div>
          {variations.map((data: any, index: number) => (
            <div key={index} ref={(el) => (variationRefs.current[index] = el)}>
              <VariationInformation
                errors={errors?.variations && errors?.variations[index]}
                riajGenreData={JPRecordingAssociationGenreOptions}
                data={data}
                onClickError={onClickVariationError}
                nameRef={(el: any) => (nameRef.current[index] = el)}
                musicFormatRef={(el: any) => (musicFormatRef.current[index] = el)}
                saleTokenDateRef={(el: any) => (saleTokenDateRef.current[index] = el)}
                downloadDateRef={(el: any) => (downloadDateRef.current[index] = el)}
                notiDownloadTimeRef={(el: any) => (notiDownloadTimeRef.current[index] = el)}
                productCodeRef={(el: any) => (productCodeRef.current[index] = el)}
                janCodeRef={(el: any) => (janCodeRef.current[index] = el)}
                priceRef={(el: any) => (priceRef.current[index] = el)}
                coverImageUrlRef={(el: any) => (coverImageUrlRef.current[index] = el)}
                bookletsRef={(el: any) => (bookletsRef.current[index] = el)}
                songsRef={(el: any) => (songsRef.current[index] = el)}
                videosRef={(el: any) => (videosRef.current[index] = el)}
                imagesRef={(el: any) => (imagesRef.current[index] = el)}
                jarsacContentRef={(el: any) => (includeJasracContentRef.current[index] = el)}
                jmdCooperationRef={(el: any) => (jmdCooperationRef.current[index] = el)}
                riajGenreIdRef={(el: any) => (riajGenreIdRef.current[index] = el)}
                jmdMusicGenreIdRef={(el: any) => (jmdMusicGenreIdRef.current[index] = el)}
                jmdVideoGenreRef={(el: any) => (jmdVideoGenreRef.current[index] = el)}
              />
            </div>
          ))}
        </CardContainer>

        <div
          className={`d-flex justify-content-center align-items-center mt-4 py-4 ${
            props?.isRoleManager || 'pe-none opacity-50'
          }`}
        >
          <Button
            variant="primary me-5"
            className="btn-action btn-back-title"
            onClick={() => handleBackStep()}
            disabled={fetchTitleFail}
          >
            前のステップへ戻る
          </Button>
          <Button
            variant="primary me-5"
            className="btn-action btn-focus-none"
            disabled={isDisableBtn}
            onClick={() => handleValidateSaveDraft()}
          >
            ⼀時保存する
          </Button>
          <Button
            variant="secondary"
            disabled={fetchTitleFail}
            className="btn-action"
            onClick={() => handleValidateForm()}
          >
            この内容で登録する
          </Button>
        </div>
        <ModalComponent
          show={visableModal}
          onCloseModal={() => setVisibleModal(false)}
          classNameContent="d-flex align-items-center content-body-modal justify-content-center"
          variantButtonSubmit="primary"
          onSubmitModal={() => handleCreateAndUpdate()}
        >
          <div>
            <p className="mb-2">タイトルを登録します。</p>
            <p className="mb-2">登録後、情報公開解禁日時を過ぎると内容の変更が出来なくなります。</p>
            <p className="mb-2">よろしいですか？</p>
          </div>
        </ModalComponent>
      </Container>
    </>
  );
});

export default Review;
