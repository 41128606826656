import React, { useImperativeHandle, forwardRef, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { isEmpty, find } from 'lodash';
import {
  setCurrentStep,
  addVariationAction,
  setTriggerValidate,
  setTitleForm,
  setTitleDuplicateId,
} from 'App/Features/Title';
import { useAppDispatch, useAppSelector } from 'App/Store';
import CardContainer from 'Components/Common/CardContainer';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import { SelectValue } from 'Types/Common';
import { INCLUDE_JARSAC_CONTENT, STEPS, VARIATION_INIT } from 'Constant/Title';
import { TitleApi } from 'Datasource/TitleApi';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { handleConvertTitleDuplicate, randomId } from 'Utils/Title';
import { halfWidthValidation, katakanaFullSizeValidation, maxMsg } from 'Utils/Validation';
import { isReviewTitle } from 'Utils/File';
import ReactTooltip from 'react-tooltip';
import { ExclamationCircle } from 'react-bootstrap-icons';
import useMessage from 'Hooks/useMessage';
import FormSelectArtist from 'Components/Common/Select/FormSelectArtist';
import { ActionType } from 'Constant';
import FormGroupSearch from 'Components/Common/Form/FormGroupSearch';
import { getBool } from 'Utils/Permission';
import { titleText } from 'Constant/Texts/Title';

export const schemaTitleInfo = yup.object().shape({
  artistId: yup.string().required('入力してください。').nullable(),
  name: yup.string().required('入力してください。').max(255, maxMsg(255)).trim().nullable(),
  furiganaName: katakanaFullSizeValidation({}).max(255, maxMsg(255)).trim().nullable(),
  label: yup.string().required('入力してください。').max(100, maxMsg(100)).trim().nullable(),
  musicGenreId: yup.number().required('入力してください。').nullable(),
  globalName: halfWidthValidation().nullable().max(100, maxMsg(100)),
});

const TitleInformation = forwardRef((props: any, ref: any): JSX.Element => {
  const titleState = useAppSelector((state) => state.title);
  const { isValidate, isValidateSaveDraft } = useAppSelector((state) => state.title);

  const dispatch = useAppDispatch();
  const {
    control,
    register,
    getValues,
    trigger,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      artistId: titleState.titleForm.artistId,
      artistName: titleState.titleForm.artistName,
      name: titleState.titleForm.name,
      furiganaName: titleState.titleForm.furiganaName,
      label: titleState.titleForm.label,
      musicGenreId: titleState.titleForm.musicGenreId,
      sellIap: titleState.titleForm.sellIap || 0,
      globalName: titleState.titleForm.globalName,
    },
    resolver: yupResolver(schemaTitleInfo),
  });

  const { titleForm } = useAppSelector((state) => state.title);

  const [loading, setLoading] = useState<boolean>(false);
  const [musicGenre, setMusicGenre] = useState<any>();
  const location = useLocation<any>();
  const { state } = location;
  const idDuplicate = state?.idDuplicate || null;
  const { openMessageError } = useMessage();

  const [musicSearchKey, setMusicSearchKey] = useState<string>('');

  const handleGetTitleDuplicate = async () => {
    if (!idDuplicate) return;

    // Fix bug 92531
    if (titleState.titleDuplicateId && idDuplicate === titleState.titleDuplicateId) {
      return;
    }

    try {
      setLoading(true);
      const {
        data: { data },
      } = await TitleApi.getTitle({ id: idDuplicate });

      const titleInfoData: any = handleConvertTitleDuplicate(data);
      delete titleInfoData['id'];

      //* CR 77081 do not copy productCode, janCode, coverImageUrl
      const variationCopies = titleInfoData?.variations.map((v: any) => ({
        ...v,
        productCode: null,
        janCode: null,
        coverImageUrl: null,
        includeJasracContent:
          v?.includeJasracContent !== null
            ? v?.includeJasracContent
            : INCLUDE_JARSAC_CONTENT.INCLUDE,
        id: randomId(),
      }));
      dispatch(setTitleForm({ ...titleInfoData, isAuthor: '1', variations: variationCopies }));
      dispatch(setTitleDuplicateId(idDuplicate)); // Fix bug 92531
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };
  setValue('artistId', titleState.titleForm.artistId);

  useEffect(() => {
    handleGetTitleDuplicate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDuplicate]);

  const handleOnchangeArtist = (item: any) => {
    clearErrors('artistId');
    let info = { ...titleForm };
    if (!item) {
      info = { ...titleForm, artistId: null, artistName: null };
      reset({
        artistId: null,
        artistName: null,
      });
    } else {
      info = info = { ...titleForm, artistId: item.value, artistName: item.label };
      reset({
        artistId: item.value,
        artistName: item.label,
      });
    }

    dispatch(setTitleForm(info));
  };

  const handleChangeInput = (value: any, type: string) => {
    const info = { ...titleForm, [type]: value };
    dispatch(setTitleForm(info));
  };

  const handleNextStep = async () => {
    const result = await trigger('artistId');
    clearErrors('name');
    clearErrors('furiganaName');
    clearErrors('label');
    clearErrors('musicGenreId');
    clearErrors('globalName');

    if (result) {
      if (isEmpty(titleState.titleForm.variations)) {
        dispatch(addVariationAction({ variation: { ...VARIATION_INIT, id: randomId() } }));
      }
      dispatch(setCurrentStep(STEPS.CREATE_VARIATION));
    }
  };

  useImperativeHandle(ref, () => ({
    handleNextStepFromParent() {
      handleNextStep();
      dispatch(setTriggerValidate(false));
    },
  }));

  const handleRenderMusicGenre = useCallback(() => {
    const exits = find(
      musicGenre,
      (music: { label: string; value: number }) => music.value === titleForm.musicGenreId,
    );
    return exits;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleForm.musicGenreId, musicGenre]);

  const handleValidation = async () => {
    await trigger();
  };

  const handleGetMusicGenre = async () => {
    const {
      data: { data },
    } = await TitleApi.getMusicGenres();
    const results = data.map((musicGenre: { name: string; id: number }) => ({
      label: musicGenre.name,
      value: musicGenre.id,
    }));
    setMusicGenre(results);
  };

  useEffect(() => {
    if (isValidate) handleValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidate]);

  const handleValidationSaveDraft = async () => {
    await trigger(['artistId', 'name', 'furiganaName', 'label'], { shouldFocus: true });
  };

  useEffect(() => {
    if (isValidateSaveDraft) {
      handleValidationSaveDraft();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidateSaveDraft]);

  useEffect(() => {
    handleGetMusicGenre();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      artistId: titleState.titleForm.artistId,
      artistName: titleState.titleForm.artistName,
      name: titleState.titleForm.name,
      label: titleState.titleForm.label,
      musicGenreId: titleState.titleForm.musicGenreId,
      furiganaName: titleState.titleForm.furiganaName,
      globalName: titleState.titleForm.globalName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleForm.id]);

  const variation = titleState?.titleForm?.variations[0];

  const handleCheckValidate = useCallback(() => {
    reset({
      artistId: titleState.titleForm.artistId,
      artistName: titleState.titleForm.artistName,
      name: titleState.titleForm.name,
      label: titleState.titleForm.label,
      musicGenreId: titleState.titleForm.musicGenreId,
      furiganaName: titleState.titleForm.furiganaName,
      globalName: titleState.titleForm.globalName,
    });
    trigger(['name', 'artistId', 'furiganaName', 'label', 'musicGenreId', 'globalName']);
  }, [trigger, titleState]);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Container className="my-2">
        <CardContainer darkHeader title="タイトル情報">
          <form>
            <FormSelectArtist
              defaultValue={titleForm.artistName}
              inputSearchString={getValues('artistName')}
              rowClass="mb-3"
              label="アーティスト*"
              labelMd="3"
              colMd="9"
              onSelect={(selectedItem: SelectValue) => handleOnchangeArtist(selectedItem)}
              placeholder="アーティストを選択してください"
              errorMessage={errors.artistId?.message}
              isReview={isReviewTitle('artistId', variation)}
              permission={titleState?.titleForm?.id ? ActionType.EDIT : ActionType.CREATE}
              onInput={(value: string) => {
                if (!value) handleOnchangeArtist(null);
              }}
            />

            <FormGroupInput
              label="タイトル名*"
              labelMd="3"
              colMd="9"
              register={register('name')}
              value={titleForm.name}
              errorMessage={errors.name?.message}
              onChange={(value) => handleChangeInput(value, 'name')}
              isReview={isReviewTitle('nameTitle', variation)}
            />

            <FormGroupInput
              label="タイトル名（フリガナ）"
              labelMd="3"
              colMd="9"
              register={register('furiganaName')}
              value={titleForm.furiganaName}
              errorMessage={errors.furiganaName?.message}
              onChange={(value) => handleChangeInput(value, 'furiganaName')}
              isReview={isReviewTitle('furiganaName', variation)}
            />

            <FormGroupInput
              label="タイトル名（英語表記）"
              labelMd="3"
              colMd="9"
              register={register('globalName')}
              value={titleForm.globalName}
              errorMessage={errors.globalName?.message}
              onChange={(value) => handleChangeInput(value, 'globalName')}
              isReview={isReviewTitle('globalName', variation)}
            />

            <Controller
              name="musicGenreId"
              control={control}
              render={() => (
                <FormGroupSearch
                  defaultValue={handleRenderMusicGenre()?.label}
                  label="miim音楽ジャンル"
                  placeholder="ジャンルを選択してください"
                  labelMd="3"
                  colMd="4"
                  options={
                    musicSearchKey
                      ? musicGenre.filter((item: any) =>
                          item.label
                            .trim()
                            .toUpperCase()
                            .includes(musicSearchKey.trim().toUpperCase()),
                        )
                      : musicGenre
                  }
                  onSelect={(item: any) => {
                    let info = {
                      ...titleForm,
                    };
                    if (!isEmpty(item)) {
                      info = {
                        ...titleForm,
                        musicGenreId: item.value,
                        musicGenreName: item.label,
                      };
                    } else {
                      info = {
                        ...titleForm,
                        musicGenreId: null,
                        musicGenreName: '',
                      };
                    }
                    dispatch(setTitleForm(info));
                  }}
                  rowClass="mb-3"
                  errorMessage={errors.musicGenreId?.message}
                  disabled={isReviewTitle('musicGenreId', variation)}
                  onInputChange={(keyword: string) => {
                    if (!keyword) {
                      const info = { ...titleForm, musicGenreId: null, musicGenreName: '' };
                      dispatch(setTitleForm(info));
                      setValue('musicGenreId', null);
                    } else {
                      setMusicSearchKey(keyword);
                    }
                  }}
                  onMenuFocus={() => setMusicSearchKey('')}
                  showIconClear
                />
              )}
            />
            <FormGroupInput
              label="レーベル名"
              labelMd="3"
              colMd="9"
              value={titleForm.label}
              register={register('label')}
              onChange={(value) => handleChangeInput(value, 'label')}
              errorMessage={errors.label?.message}
              isReview={isReviewTitle('label', variation)}
            />
            <Form.Group as={Row} className="mb-3 d-flex flex-row align-items-baseline">
              <Form.Label column md={2}>
                {titleText.iap.label}
              </Form.Label>
              <Col md={{ span: 9, offset: 1 }} className="px-0">
                <Form.Check
                  {...register}
                  type="checkbox"
                  id={`checkbox-iap`}
                  label="販売する"
                  className="mb-0 ms-3 check-box-ct"
                  checked={getBool(titleForm.sellIap)}
                  onChange={(e) => handleChangeInput(e.target.checked ? 1 : 0, 'sellIap')}
                />
                <p className="ps-3 fs-12 text-prev-wrap">{titleText.iap.description}</p>
              </Col>
            </Form.Group>
          </form>
        </CardContainer>
        <div className="d-flex justify-content-center align-items-center flex-column mt-4">
          <ReactTooltip id={'title-step-1'} place="top" effect="solid" className="h-35 text-center">
            マスタタイトルの登録に必須となる入力を確認します
          </ReactTooltip>
          <Button
            variant="primary"
            className="btn-action btn-focus-none mb-3 btn-valid-title"
            onClick={handleCheckValidate}
          >
            入力内容を確認する
            <ExclamationCircle
              size={20}
              className="ms-2 outline-none"
              data-tip
              data-for={'title-step-1'}
            />
          </Button>

          <Button variant="primary" onClick={handleNextStep} className="btn-action btn-focus-none">
            次のステップへ進む
          </Button>
        </div>
      </Container>
    </>
  );
});

export default TitleInformation;
