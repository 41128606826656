import React, { useState, useRef, useEffect, useCallback, useMemo, useLayoutEffect } from 'react';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import { useForm, Controller } from 'react-hook-form';
import { ICheckBox } from 'Types/Common';
import { find, findLastIndex, flatMap, includes, isEmpty, omit, toNumber } from 'lodash';
import GroupCheckBox from 'Components/Common/Form/GroupCheckBox';
import { Col, Form, FormControl, Row, Button, ButtonGroup } from 'react-bootstrap';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  englishSlashesValidationNotRequired,
  englishValidationNotRequired,
  isrcCodeValidation,
  jasracCodeValidation,
  katakanaFullSizeSlashesNotRequired,
  katakanaFullSizeValidation,
  kanjiSlashesNotRequired,
  numberRequired,
  otherJasracCodeEnglishValidation,
  songTimeValidation,
  stringRequired,
  maxMsg,
  SuggestMsg,
} from 'Utils/Validation';
import MessageError from 'Components/Common/MessageError';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import { TYPE_MEDIA } from 'Constant';
import { UploadMediaApi } from 'Datasource/UploadMedia';
import useMessage from 'Hooks/useMessage';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { calcMb, checkFileSize, checkTypeFile, isAfterDownload } from 'Utils/File';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import { audioTypes, defaultValueState, MaxLength, MusicFormat } from 'Constant/Song';
import {
  checkAutoFormatJasrac,
  checklNumberRemainCapacity,
  convertToClock,
  convertToSeconds,
  handleConvertSongInformationFee,
  handleConvertVideoInformationFee,
} from 'Utils/Title';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { MAX_INPUT } from 'Constant';
import ButtonCountDown from 'Components/Common/Button/ButtonCountDown';
import AudioCustom from 'Components/Common/AudioCustom/Audio';
import Select, { InputActionMeta } from 'react-select';
import { useAppSelector } from 'App/Store';
import { Song, Variation } from 'Types/App/title';
import { INCLUDE_JARSAC_CONTENT, LINK_JMD_TYPE_CONTENT } from 'Constant/Title';
import { TitleApi } from 'Datasource/TitleApi';
import { formatPrice } from 'Utils/Numbers';
import FormGroupSelect from 'Components/Common/Form/FormGroupSelect';
import { KanjiMsgUI } from '../components_valid/KanjiMsgUI';
import KatakanaMsgUI from '../components_valid/KatakanaMsgUI';
import EnglishMsgUI from '../components_valid/EnglishMsgUI';
interface SongInformationProps {
  id: string;
  onCloseModal: () => void;
  isReview?: boolean;
  submitSongInfo?: (songInfo: any) => void;
  submitSongVideoInfo?: (songInfo: any) => void;
  songInfo: any;
  currentSong: number | undefined;
  isDiabled?: boolean;
  isVideo?: boolean;
  variation?: any;
}
interface SelectItem {
  value: number | string;
  label: string;
  lyrics?: string;
}

const schema = yup.object().shape({
  name: stringRequired({}).trim().nullable(),
  furiganaName: katakanaFullSizeValidation({}).max(100, maxMsg(100)).trim().nullable(),
  musicianName: kanjiSlashesNotRequired()
    .when('ivtType', {
      is: 0,
      then: yup.string().nullable().trim(),
      otherwise: yup.string().required('入力してください。').nullable().trim(),
    })
    .trim()
    .nullable()
    .max(MaxLength.musicianName, maxMsg(MaxLength.musicianName)),
  composerName: yup
    .string()
    .when('ivtType', {
      is: 2,
      then: yup.string().nullable().trim(),
      otherwise: yup.string().required('入力してください。').nullable().trim(),
    })
    .nullable()
    .max(MaxLength.composerName, maxMsg(MaxLength.composerName)),
  arrangerName: yup
    .string()
    .nullable()
    .max(MaxLength.arrangerName, maxMsg(MaxLength.arrangerName))
    .trim(),
  showTime: songTimeValidation({}).trim().nullable(),
  versionIdentificationType: yup.number().required('入力してください。').nullable(),
  ivtType: yup.number().required('入力してください。').nullable(),
  liveType: yup.number().required('入力してください。').nullable(),
  authorizedGroupType: yup
    .number()
    .when('includeJasracContent', {
      is: (includeJasracContent: number | null) =>
        includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE,
      then: numberRequired({}).nullable(),
    })
    .nullable(),
  authorizedGroup: yup
    .string()
    .when('authorizedGroupType', {
      is: 4,
      then: yup.string().required('入力してください。').nullable().trim(),
    })
    .trim()
    .nullable(),
  informationFee: englishValidationNotRequired().nullable(),
  lyricInformationFee: englishValidationNotRequired().nullable(),
  lyricsClassifyType: yup.number().when('ivtType', {
    is: (val: number) => val === 1 || val === 2,
    then: yup.number().required('入力してください。').nullable(),
    otherwise: yup.number().notRequired().nullable(),
  }),
  englishName: englishValidationNotRequired().trim().nullable(),
  musicianFuriganaName: katakanaFullSizeSlashesNotRequired()
    .trim()
    .nullable()
    .max(MaxLength.musicianFuriganaName, maxMsg(MaxLength.musicianFuriganaName)),
  musicianEnglishName: englishSlashesValidationNotRequired()
    .trim()
    .nullable()
    .max(MaxLength.musicianEnglishName, maxMsg(MaxLength.musicianEnglishName)),
  composerFuriganaName: katakanaFullSizeSlashesNotRequired()
    .trim()
    .nullable()
    .max(MaxLength.composerFuriganaName, maxMsg(MaxLength.composerFuriganaName)),
  composerEnglishName: englishSlashesValidationNotRequired()
    .trim()
    .nullable()
    .max(MaxLength.composerEnglishName, maxMsg(MaxLength.composerEnglishName)),
  arrangerFuriganaName: katakanaFullSizeSlashesNotRequired()
    .trim()
    .nullable()
    .max(MaxLength.arrangerFuriganaName, maxMsg(MaxLength.arrangerFuriganaName)),
  arrangerEnglishName: englishSlashesValidationNotRequired()
    .trim()
    .nullable()
    .max(MaxLength.arrangerEnglishName, maxMsg(MaxLength.arrangerEnglishName)),
  isrcCode: isrcCodeValidation().trim().nullable().max(12, maxMsg(12)),
  jasracCode: yup.string().when('authorizedGroupType', {
    is: (val: number) => val === 1,
    then: jasracCodeValidation().trim().nullable(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  otherJasracCode: yup
    .string()
    .max(9, maxMsg(9))
    .when('authorizedGroupType', {
      is: (val: number) => includes([2], val),
      then: otherJasracCodeEnglishValidation().nullable().max(9, maxMsg(9)),
      otherwise: yup.string().notRequired().nullable(),
    }),
  sourceAudioUrl: yup.string().when('isVideo', {
    is: (val: boolean) => !val,
    then: stringRequired({}).trim().nullable(),
  }),
  riajGenreId: yup
    .number()
    .when('jmdCooperation', {
      is: (jmdCooperation: number | null) => jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
      then: numberRequired({}).nullable(),
    })
    .nullable(),
});

const SongInformation = ({
  onCloseModal,
  isReview = false,
  submitSongInfo,
  submitSongVideoInfo,
  songInfo,
  id,
  isDiabled,
  isVideo = false,
  variation,
}: SongInformationProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: songInfo.name,
      id: songInfo.id,
      furiganaName: songInfo.furiganaName,
      englishName: songInfo.englishName,
      musicianName: songInfo.musicianName,
      musicianFuriganaName: songInfo?.musicianFuriganaName || null,
      musicianEnglishName: songInfo.musicianEnglishName,
      composerName: songInfo.composerName,
      composerFuriganaName: songInfo.composerFuriganaName || null,
      composerEnglishName: songInfo.composerEnglishName,
      arrangerName: songInfo.arrangerName,
      arrangerFuriganaName: songInfo.arrangerFuriganaName || null,
      arrangerEnglishName: songInfo.arrangerEnglishName,
      showTime: songInfo.showTime,
      versionIdentificationType: songInfo.versionIdentificationType,
      ivtType: songInfo.ivtType,
      liveType: songInfo.liveType,
      authorizedGroup: songInfo.authorizedGroup,
      authorizedGroupType: songInfo.authorizedGroupType,
      isrcCode: songInfo.isrcCode,
      jasracCode: songInfo.jasracCode,
      otherJasracCode: songInfo.otherJasracCode,
      informationFee: songInfo.informationFee,
      lyricsClassifyType: songInfo.lyricsClassifyType,
      sourceAudioUrl: songInfo.sourceAudioUrl || undefined,
      size: songInfo.size,
      isVideo: isVideo,
      fileName: songInfo.fileName,
      lyricInformationFee: songInfo.lyricInformationFee,
      lyrics: songInfo.lyrics,
      includeJasracContent: variation?.includeJasracContent,
      riajGenreId: songInfo.riajGenreId,
    },
    resolver: yupResolver(schema),
  });

  const { titleForm } = useAppSelector((state) => state.title);
  const { variations } = titleForm;

  const [JPRecordingAssociationGenreOptions, setJPRecordingAssociationGenreOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [versionIdentificationType, setVersionIdentificationType] = useState<Array<ICheckBox>>([
    {
      label: 'L（国内原版）',
      checked: getValues('versionIdentificationType') === 0,
      value: 0,
    },
    {
      label: 'I（外国原版）',
      checked: getValues('versionIdentificationType') === 1,
      value: 1,
    },
  ]);

  const [ivtType, setIvtType] = useState<Array<ICheckBox>>([
    {
      label: 'I（Vocalなし）',
      checked: getValues('ivtType') === 0,
      value: 0,
    },
    {
      label: 'V（Vocalあり）',
      checked: getValues('ivtType') === 1,
      value: 1,
    },
  ]);

  const [liveType, setLiveType] = useState<Array<ICheckBox>>([
    {
      label: 'スタジオ',
      checked: getValues('liveType') === 0,
      value: 0,
    },
    {
      label: 'ライブ',
      checked: getValues('liveType') === 1,
      value: 1,
    },
  ]);

  const [lyricsClassifyType, setLyricsClassifyType] = useState<Array<ICheckBox>>([
    {
      label: '１（原詞)',
      checked: getValues('lyricsClassifyType') === 1,
      value: 1,
    },
    {
      label: '２（訳詞)',
      checked: getValues('lyricsClassifyType') === 2,
      value: 2,
    },
    {
      label: '３（不明）',
      checked: getValues('lyricsClassifyType') === 3,
      value: 3,
    },
    {
      label: ' ブランク（Vocalなし）',
      checked: getValues('lyricsClassifyType') === 4,
      value: 4,
    },
  ]);
  const [authorizedGroupType, setAuthorizedGroupType] = useState<ICheckBox[]>([
    {
      label: 'JASRAC',
      checked: getValues('authorizedGroupType') === 1,
      value: 1,
      disabled: variation?.includeJasracContent !== INCLUDE_JARSAC_CONTENT.INCLUDE,
    },
    {
      label: 'NexTone',
      checked: getValues('authorizedGroupType') === 2,
      value: 2,
      disabled: variation?.includeJasracContent !== INCLUDE_JARSAC_CONTENT.INCLUDE,
    },
    {
      label: '自己管理',
      checked: getValues('authorizedGroupType') === 3,
      value: 3,
      disabled: false,
    },
    {
      label: 'その他(入力)',
      checked: getValues('authorizedGroupType') === 4,
      value: 4,
      disabled: false,
    },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentTimeSong, setCurrentTimeSong] = useState<any>({});
  const [isPreview, setIsPreview] = useState<boolean>(false);

  const [disableEnter, setDisableEnter] = useState<boolean>(false);
  const { openMessageError, openMessage } = useMessage();

  const lyricRef = useRef([]) as any;
  const [valuesForm, setValuesForm] = useState(songInfo?.name ? songInfo : defaultValueState);

  const [songSelected, setSongSelected] = useState<SelectItem | null>(null);

  useEffect(() => {
    if (getValues('authorizedGroupType') !== 4) setValue('authorizedGroup', null);
  }, [getValues('authorizedGroupType')]);

  const onSubmit = async (values: any) => {
    const isValid = await trigger();
    const isValidAudioType = handleValidAudioType();
    const result = omit(
      {
        ...values,
        lyrics: JSON.stringify(lineLyric),
        informationFee: values.informationFee ? values.informationFee : 0,
        lyricInformationFee: values.lyricInformationFee ? values.lyricInformationFee : 0,
      },
      ['includeJasracContent'],
    );

    //* valid audio type when submit
    if (!isVideo) {
      if (!isValidAudioType) return;
    }
    //* ===========================
    if (isValid) {
      if (isVideo) {
        submitSongVideoInfo && submitSongVideoInfo(result);
      } else {
        submitSongInfo && submitSongInfo(result);
      }

      onCloseModal();
    }
  };

  const [songUrl, setSongUrl] = useState<string>(getValues('sourceAudioUrl') || '');
  const uploadSongRef = useRef() as any;
  const isEnableCopyRight = find(authorizedGroupType, (copy) => copy.value === 4 && copy.checked);

  const uploadSong = async (song: Blob) => {
    try {
      setLoading(true);
      const formData: FormData = new FormData();
      formData.append('type', TYPE_MEDIA.SONGS.toString());
      formData.append('file', song);
      const {
        data: { url },
      } = await UploadMediaApi.upLoadMedia(formData);

      return url;
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
    return '';
  };

  const onChangeSong = async (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors('sourceAudioUrl');
    const target = e.target as HTMLInputElement;
    const selectedFile: File = (target.files as FileList)[0];
    const resetFile = () => {
      uploadSongRef.current.value = '';
    };
    if (!target.files) {
      resetFile();
      return;
    }
    const type = selectedFile.type;
    const musicFormat = variation?.musicFormat;

    if (checklNumberRemainCapacity(variation, selectedFile?.size)) {
      resetFile();
      return;
    }
    const isValidType = checkTypeFile(selectedFile, audioTypes);
    if (!isValidType) {
      setError('sourceAudioUrl', {
        type: 'manual',
        message:
          '⾳源ファイルは20MB以下のMP3ファイルまたは150MB以下のWAVファイルを登録してください。',
      });
      resetFile();
      return;
    }
    if (type === audioTypes[0]) {
      const isValidSize = checkFileSize(selectedFile, 0, 20);
      if (!isValidSize) {
        setError('sourceAudioUrl', {
          type: 'manual',
          message:
            '⾳源ファイルは20MB以下のMP3ファイルまたは150MB以下のWAVファイルを登録してください。',
        });
        resetFile();
        return;
      }

      if (musicFormat === MusicFormat.WAV) {
        openMessage({
          variant: 'error',
          message: '⾳源ファイルは.wavの⾳源を登録してください。',
        });
        resetFile();
        return;
      }
    }
    if (includes([audioTypes[1], audioTypes[2], audioTypes[3], audioTypes[4]], type)) {
      const isValidSize = checkFileSize(selectedFile, 0, 150);
      if (!isValidSize) {
        setError('sourceAudioUrl', {
          type: 'manual',
          message:
            '⾳源ファイルは20MB以下のMP3ファイルまたは150MB以下のWAVファイルを登録してください。',
        });
        resetFile();
        return;
      }

      if (musicFormat === MusicFormat.MP3) {
        openMessage({
          variant: 'error',
          message: '⾳源ファイルは.mp3の⾳源を登録してください。',
        });
        resetFile();
        return;
      }
    }

    const url = await uploadSong(selectedFile);
    setValue('sourceAudioUrl', url);
    setValue('size', selectedFile.size);
    setValue('fileName', selectedFile.name);
    setSongUrl(url);
    resetFile();
  };

  const handleChangeInput = (type: any, callback: () => void) => {
    if (!type) return;
    callback();
    clearErrors(type);
  };

  const onChangeCodeJasrac = (value: string) => {
    const valueFormat = checkAutoFormatJasrac(value.replace(/[^\w\s]/gi, ''));
    if (valueFormat !== undefined) {
      setValue('jasracCode', valueFormat);
      setValuesForm({ ...valuesForm, jasracCode: valueFormat || '' });
      clearErrors('jasracCode');
    }
  };

  //HANDLE LYRICS
  const [lineLyric, setLineLyric] = useState<any>([]);
  const [onSelectRow, setOnSelectRow] = useState<number>(-1);
  const [isPaused, setIsPaused] = useState<boolean>(true);
  const [isCountDownBtn, setIsCountDownBtn] = useState<boolean>(false);
  const audioPlayer = useRef<any>(null);

  const optionSongs = useMemo(() => {
    //* return songs different current songInfo
    const songsOfCurrentVariation = [...variation.songs]
      .filter((song: Song) => song.lyrics && song.sourceAudioUrl && song.index !== songInfo.index)
      .map((song: Song) => {
        let labelName = `${variation.name} - ${song.name}`;
        if (!variation.name) {
          if (variation.type === 0) {
            labelName = `通常盤 - ${song.name}`;
          } else {
            labelName = `${song.name}`;
          }
        }
        return {
          label: labelName,
          value: song.index,
          lyrics: song.lyrics ? song.lyrics : '',
        };
      });

    const songs = flatMap(variations, ({ songs, index, name, type }) => {
      const variationIndex = index;
      const songList: Song[] = songs || [];
      return songList
        .filter(
          (song: Song) => song.lyrics && song.sourceAudioUrl && variationIndex !== variation.index,
        )
        .map((song: Song) => {
          let labelName = `${name} - ${song.name}`;
          if (!name) {
            if (type === 0) {
              labelName = `通常盤 - ${song.name}`;
            } else {
              labelName = `${song.name}`;
            }
          }
          return {
            label: labelName,
            value: song.index,
            lyrics: song.lyrics ? song.lyrics : '',
          };
        });
    });

    const finalyList = [...songsOfCurrentVariation, ...songs];

    return finalyList.map((song: SelectItem, index: number) => ({
      ...song,
      value: index,
    }));
  }, [
    songInfo.index,
    variation.index,
    variation.name,
    variation.songs,
    variation.type,
    variations,
  ]);

  useEffect(() => {
    if (songInfo.lyrics) {
      setLineLyric(JSON.parse(songInfo.lyrics) || []);
    }
  }, [songInfo.lyrics]);

  const renderTxt = async (e: any) => {
    try {
      const { name, size } = e.target?.files[0];
      if (name?.split('.').pop() !== 'txt') {
        toast('txt以外のファイルが選択されています。', { type: 'error' });
        return;
      }
      if (calcMb(size) > 2) {
        toast('TXTファイルは2.0MB以内のファイルを登録してください。', { type: 'error' });
        return;
      }
      e.preventDefault();
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e?.target?.result || '';
        const splitted = text.toString().split('\n');
        const arrLyric = [];
        for (let i = 0; i < splitted.length; i++) {
          const element = { time: '', content: splitted[i] };
          arrLyric.push(element);
        }

        setLineLyric(arrLyric);
      };
      reader.readAsText(e.target.files[0]);
    } catch (error) {
      //TODO:
    } finally {
      e.target.value = '';
    }
  };

  const isContentLyrics = (item: any) => {
    return item?.content?.trim().length === 0 || item?.content === '\r';
  };

  const onHandleRecordSongTime = () => {
    const { paused } = audioPlayer.current;
    if (paused) {
      audioPlayer.current.play();
      setIsPaused(false);
    } else {
      audioPlayer.current.pause();
      setIsPaused(true);
    }
    setIsPreview(false);
  };

  const onHandleEnter = () => {
    const { currentTime } = audioPlayer.current;

    if (onSelectRow >= lineLyric.length || onSelectRow < 0) {
      setOnSelectRow(0);
    } else {
      let i = onSelectRow;
      while (i < lineLyric.length) {
        i++;
        if (!lineLyric[i]) {
          i = 0;
        }
        if (!isContentLyrics(lineLyric[i])) {
          setOnSelectRow(i);
          // audioPlayer.current.pause();
          onSelectRow;
          const currentLyric = [...lineLyric];
          currentLyric[onSelectRow].time = convertToClock(currentTime);
          setLineLyric(currentLyric);
          if (audioPlayer?.current?.duration > 0 && !audioPlayer?.current?.paused) {
            //* if index === last index -> scroll to first item

            if (Array.isArray(lineLyric) && onSelectRow < [...lineLyric].length - 1) {
              lyricRef.current[onSelectRow]?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
              });
            } else {
              lyricRef.current[0]?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
              });
            }
          }
          return;
        }
      }
    }
  };

  const setSpeed = (speed: number) => {
    audioPlayer.current.playbackRate = speed;
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (lineLyric.length > 0 && songUrl && !isPreview && !disableEnter) {
          onHandleEnter();
        }
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [lineLyric, onSelectRow, songUrl, isPreview, disableEnter]);

  const onLoadedMetadata = () => {
    if (audioPlayer?.current) {
      const seconds = audioPlayer?.current?.duration;
      const result = new Date(seconds * 1000).toISOString().slice(11, 19);
      handleChangeInput('showTime', () => setValue('showTime', result));
    }
  };

  //TODO: handle valid audio type when audio file existed & click submit
  const handleValidAudioType = () => {
    const musicFormat = variation?.musicFormat;
    if (!getValues('sourceAudioUrl')) return;
    const sourceAudioUrl = getValues('sourceAudioUrl');
    const isWAV = sourceAudioUrl.search('.wav') > 0;

    if (musicFormat === MusicFormat.MP3 && isWAV) {
      setError('sourceAudioUrl', {
        type: 'manual',
        message: '⾳源ファイルは.mp3の⾳源を登録してください。',
      });
      return false;
    }

    if (musicFormat === MusicFormat.WAV && !isWAV) {
      setError('sourceAudioUrl', {
        type: 'manual',
        message: '⾳源ファイルは.wavの⾳源を登録してください。',
      });
      return false;
    }

    return true;
  };

  const isCurrentTimeSong = useCallback(
    (item: any, index: number) => {
      if (item.time === '') return false;

      const currentTime = +currentTimeSong[0];

      const greaterTime = +currentTime >= convertToSeconds(item?.time);

      let indexTime = 1;
      for (let i = 1; i < lineLyric.length; i++) {
        if (lineLyric[index + i]?.time) {
          indexTime = i;
          break;
        }
      }
      const lastIndex = findLastIndex(lineLyric, function (el: any) {
        return el.time;
      });
      const thisTime = +currentTime < convertToSeconds(lineLyric[index + indexTime]?.time);

      // CHECK LAST LYRIC
      if (lastIndex == index && greaterTime) return true;

      // CHECK LYRIC CURRENT
      return greaterTime && thisTime;
    },
    [currentTimeSong, lineLyric],
  );

  useEffect(() => {
    if (isPreview) {
      [...lineLyric].forEach((item: any, index: number) => {
        if (isCurrentTimeSong(item, index)) {
          setOnSelectRow(index);
        }
      });
    }
  }, [isCurrentTimeSong, isPreview, lineLyric]);

  useLayoutEffect(() => {
    if (onSelectRow >= 0 && isPreview) {
      lyricRef.current[onSelectRow]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  }, [isPreview, onSelectRow]);

  useEffect(() => {
    if (isPreview && isEmpty(currentTimeSong)) {
      setOnSelectRow(-1);
      lyricRef.current[0]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  }, [isPreview, currentTimeSong]);

  const handlePasteLyric = () => {
    if (songSelected && songSelected.lyrics) {
      setLineLyric(JSON.parse(songSelected.lyrics));
    }

    if (songSelected && songSelected.lyrics && songSelected.lyrics !== '[]') {
      caculateInformationFee();
    }
  };

  const isDisableBtn = () => {
    return !lineLyric.length || !songUrl;
  };

  //* automation caculate informationFee
  const caculateInformationFee = async () => {
    const isValidPrice = variations.every(
      (variation: Variation) => variation.price && toNumber(variation.price) !== 0,
    );
    if (!isValidPrice) return;

    if (variation?.includeJasracContent !== INCLUDE_JARSAC_CONTENT.INCLUDE || isVideo) return;

    try {
      setLoading(true);

      const tmpVariations = variations.map((val: any, valIndex: number) => {
        const currentSongs = variation?.songs?.map((song: any, songIndex: number) => ({
          ...song,
          name: songInfo.index === songIndex ? valuesForm?.name : song?.name,
          lyrics:
            songInfo.index !== songIndex
              ? song?.lyrics
              : !isEmpty(lineLyric)
              ? JSON.stringify(lineLyric)
              : null,
        }));

        return { ...val, songs: valIndex === variation.index ? currentSongs : val?.songs };
      });

      const variationPayload: any[] = tmpVariations.map((val: any) => {
        const songPayloads = handleConvertSongInformationFee(val?.songs || []);
        const videoPayloads = handleConvertVideoInformationFee(val?.videos || []);

        return {
          isNormalVersion: val.index === 0,
          index: val.index,
          price: val?.price ? toNumber(val.price) : 0,
          songs: songPayloads,
          videos: videoPayloads,
          includeJasracContent: val.includeJasracContent,
        };
      });
      const {
        data: { data },
      } = await TitleApi.caculateInformationFee({ variations: variationPayload });
      const dataSorted = [...data].sort((a: any, b: any) => a.index - b.index);
      const songs = dataSorted[variation.index]?.songs || [];
      if (songs.length > 0) {
        const song = songs[songInfo.index];
        handleChangeInput('informationFee', () => {
          let fee: number | string = '';
          if (song?.songInformationFee) {
            fee =
              toNumber(song?.songInformationFee) < 100 ? 100 : toNumber(song?.songInformationFee);
          }
          setValue('informationFee', toNumber(fee));
        });

        handleChangeInput('lyricInformationFee', () => {
          let fee: number | string = '';
          if (song?.lyricInformationFee) {
            fee =
              toNumber(song?.lyricInformationFee) < 100 ? 100 : toNumber(song?.lyricInformationFee);
          }
          setValue('lyricInformationFee', toNumber(fee));
        });
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  //* update lyricInformationFee when import lyric
  useEffect(() => {
    if (lineLyric.length > 0) {
      caculateInformationFee();
      setValue('lyrics', JSON.stringify(lineLyric));
    }
  }, [lineLyric.length]);

  //* reset authorizedGroupType when change includeJarsacContent from variation
  useEffect(() => {
    const newAuthorizedGroupType = [...authorizedGroupType].map((item: any) => {
      if (item.checked && item.disabled) {
        setValue('authorizedGroupType', null);
      }
      return { ...item, checked: item.disabled && item.checked ? false : item.checked };
    });
    setAuthorizedGroupType(newAuthorizedGroupType);
  }, []);

  //* render infomartionFee UI
  const renderInformationFee = () => {
    if (isVideo) {
      return (
        <>
          {variation?.includeJasracContent !== INCLUDE_JARSAC_CONTENT.INCLUDE ? (
            <FormInputNumber
              label="情報料(みなし上代)"
              labelMd="2"
              colMd="4"
              name="informationFee"
              isReview={isReview}
              classForm="form-with-label-nowrap"
              value={getValues('informationFee')}
              thousandSeparator={true}
              allowNegative={false}
              maxLength={10}
              decimalScale={2}
              onChange={(price) =>
                handleChangeInput('informationFee', () => setValue('informationFee', price))
              }
              pureType={true}
              errorMessage={errors.informationFee?.message}
              hintText="・販売価格÷曲数"
              allowLeadingZeros
              suffix="円(税抜)"
            />
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <>
          {variation?.includeJasracContent !== INCLUDE_JARSAC_CONTENT.INCLUDE ? (
            <Form.Group as={Row} className="mb-3 form-with-label-nowrap">
              <Form.Label column md="2">
                {'情報料 (みなし上代)'}
              </Form.Label>
              <Col md="10" className="px-0">
                <Row className="position-relative">
                  <Col md="5" className="px-0">
                    <FormInputNumber
                      label="楽曲"
                      labelMd="1"
                      colMd="4"
                      name="informationFee"
                      isReview={isAfterDownload(variation)}
                      classForm="form-with-label-nowrap form-information-fee"
                      value={getValues('informationFee')}
                      thousandSeparator={true}
                      allowNegative={false}
                      maxLength={10}
                      decimalScale={2}
                      onChange={(price) =>
                        handleChangeInput('informationFee', () => setValue('informationFee', price))
                      }
                      pureType={true}
                      errorMessage={errors.informationFee?.message}
                      allowLeadingZeros
                      suffix="円(税抜)"
                    />
                  </Col>
                  <Col md="5" className="px-0 position-absolute input-lyric-fee">
                    <FormInputNumber
                      label="歌詞"
                      labelMd="1"
                      colMd="4"
                      name="lyricInformationFee"
                      isReview={
                        isAfterDownload(variation) ||
                        (Array.isArray(lineLyric) && !lineLyric.length)
                      }
                      classForm="form-with-label-nowrap form-information-fee"
                      value={getValues('lyricInformationFee')}
                      thousandSeparator={true}
                      allowNegative={false}
                      maxLength={10}
                      decimalScale={2}
                      onChange={(price) =>
                        handleChangeInput('lyricInformationFee', () =>
                          setValue('lyricInformationFee', price),
                        )
                      }
                      pureType={true}
                      errorMessage={errors.lyricInformationFee?.message}
                      allowLeadingZeros
                      suffix="円(税抜)"
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          ) : (
            <Form.Group as={Row} className="mb-3 form-with-label-nowrap">
              <Form.Label column md="2">
                {'情報料 (みなし上代)'}
              </Form.Label>
              <Col md="10">
                <p>{`楽曲: ${formatPrice(getValues('informationFee')) || '0'}円(税抜)　歌詞: ${
                  formatPrice(getValues('lyricInformationFee')) || '0'
                }円(税抜)`}</p>
                <p className="fs-12">{'・自動計算されます(最低価格税抜100円)'}</p>
              </Col>
            </Form.Group>
          )}
        </>
      );
    }
  };

  //* function JPRecordingAssociation
  const getListJPRecordingAssociation = async () => {
    const {
      data: { data },
    } = await TitleApi.getJPRecordingAssociationGenre();
    if (data.length > 0) {
      const result = data.map((item: { nameJp: string; id: number }) => {
        return { label: item.nameJp, value: item.id };
      });
      setJPRecordingAssociationGenreOptions(result);
    }
  };

  useEffect(() => {
    getListJPRecordingAssociation();
  }, []);

  const riajGenreIdValue = useMemo(() => {
    return getValues('riajGenreId');
  }, [watch('riajGenreId')]);

  useEffect(() => {
    if (!valuesForm.riajGenreId && !songInfo.riajGenreId) {
      handleChangeInput('riajGenreId', () =>
        setValuesForm({ ...valuesForm, riajGenreId: variation.riajGenreId }),
      );
      setValue('riajGenreId', variation.riajGenreId);
    }
  }, [variation.riajGenreId, songInfo]);

  const currentRiajGenreIdOption = useMemo(() => {
    return JPRecordingAssociationGenreOptions.filter(
      (option) => option.value === riajGenreIdValue,
    )?.[0];
  }, [riajGenreIdValue, JPRecordingAssociationGenreOptions]);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Form
        onSubmit={handleSubmit(onSubmit)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        id={id}
        className={'pe-auto user-select-auto'}
      >
        <FormGroupInput
          isReview={isReview}
          label="曲名*"
          labelMd="2"
          colMd="10"
          value={valuesForm?.name}
          register={register('name')}
          onChange={(value) => {
            handleChangeInput('name', () => setValuesForm({ ...valuesForm, name: value }));
            setValue('name', value);
          }}
          classForm="form-with-label-nowrap"
          errorMessage={errors.name?.message}
        />
        <FormGroupInput
          isReview={isReview}
          label="曲名(フリガナ)*"
          labelMd="2"
          colMd="10"
          value={valuesForm?.furiganaName}
          register={register('furiganaName')}
          onChange={(value) => {
            handleChangeInput('furiganaName', () =>
              setValuesForm({ ...valuesForm, furiganaName: value }),
            );
            setValue('furiganaName', value);
          }}
          classForm="form-with-label-nowrap"
          errorMessage={errors.furiganaName?.message}
          maxLength={100}
        />
        <FormGroupInput
          isReview={isReview}
          label="曲名(英語表記)"
          labelMd="2"
          colMd="10"
          value={valuesForm?.englishName}
          register={register('englishName')}
          onChange={(value) => {
            handleChangeInput('englishName', () =>
              setValuesForm({ ...valuesForm, englishName: value }),
            );
            setValue('englishName', value);
          }}
          classForm="form-with-label-nowrap"
          errorMessage={errors.englishName?.message}
        />
        {/* Miim-127 */}
        {variation.jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER && (
          <FormGroupSelect
            isReview={isReview}
            disabled={isReview}
            value={currentRiajGenreIdOption}
            styleLabel={{
              width: '16.4%',
            }}
            label="日本レコード協会ジャンル*"
            placeholder=""
            labelMd="2"
            colMd="4"
            colClass="px-3"
            classNameSlc={
              isReview && JPRecordingAssociationGenreOptions.length > 0
                ? 'pulldown-select-title-isReview'
                : 'pulldown-select-title'
            }
            options={JPRecordingAssociationGenreOptions}
            onChange={(item) => {
              handleChangeInput('riajGenreId', () =>
                setValuesForm({ ...valuesForm, riajGenreId: item?.value }),
              );
              setValue('riajGenreId', item?.value);
            }}
            rowClass="mb-3"
            errorMessage={errors.riajGenreId?.message}
          />
        )}
        <Controller
          name="ivtType"
          control={control}
          render={({ field }) => (
            <GroupCheckBox
              id="IVTIndent"
              labelForm="IVT識別*"
              name="ivtType"
              options={ivtType}
              labelMd="2"
              colMd="10"
              onChange={(value) => {
                setIvtType(value);
                const valueChecked = value.filter((o) => o.checked === true)[0];
                field.onChange(valueChecked.value);
              }}
              isReview={isReview}
              classForm="form-with-label-nowrap mb-3"
              errorMessage={errors.ivtType?.message}
            />
          )}
        />
        <FormGroupInput
          label={`作詞者名${getValues('ivtType') !== 0 ? '*' : ''}`}
          labelMd="2"
          colMd="10"
          value={valuesForm?.musicianName}
          register={register('musicianName')}
          onChange={(value) => {
            handleChangeInput('musicianName', () =>
              setValuesForm({ ...valuesForm, musicianName: value }),
            );
            setValue('musicianName', value);
          }}
          isReview={isReview}
          classForm={`form-with-label-nowrap ${
            errors.musicianName?.message === SuggestMsg ? 'mb-0' : ''
          }`}
          errorMessage={errors.musicianName?.message}
          maxLength={MaxLength.musicianName}
        />
        {errors.musicianName?.message === SuggestMsg && (
          <Row className="mb-3">
            <Col md={2}></Col>
            <Col md={10}>
              <KanjiMsgUI />
            </Col>
          </Row>
        )}
        <FormGroupInput
          isReview={isReview}
          label="作詞者名(フリガナ)"
          labelMd="2"
          colMd="10"
          maxLength={MaxLength.musicianFuriganaName}
          value={valuesForm?.musicianFuriganaName}
          register={register('musicianFuriganaName')}
          onChange={(value) => {
            handleChangeInput('musicianFuriganaName', () =>
              setValuesForm({ ...valuesForm, musicianFuriganaName: value }),
            );
            setValue('musicianFuriganaName', value);
          }}
          classForm={`form-with-label-nowrap ${
            errors.musicianFuriganaName?.message === SuggestMsg ? 'mb-0' : ''
          }`}
          errorMessage={errors.musicianFuriganaName?.message}
        />
        {errors.musicianFuriganaName?.message === SuggestMsg && (
          <Row className="mb-3">
            <Col md={2}></Col>
            <Col>
              <KatakanaMsgUI />
            </Col>
          </Row>
        )}
        <FormGroupInput
          isReview={isReview}
          label="作詞者名(英語表記)"
          labelMd="2"
          colMd="10"
          maxLength={MaxLength.musicianEnglishName}
          value={valuesForm?.musicianEnglishName}
          register={register('musicianEnglishName')}
          onChange={(value) => {
            handleChangeInput('musicianEnglishName', () =>
              setValuesForm({ ...valuesForm, musicianEnglishName: value }),
            );
            setValue('musicianEnglishName', value);
          }}
          classForm={`form-with-label-nowrap ${
            errors.musicianEnglishName?.message === SuggestMsg ? 'mb-0' : ''
          }`}
          errorMessage={errors.musicianEnglishName?.message}
        />
        {errors.musicianEnglishName?.message === SuggestMsg && (
          <Row className="mb-3">
            <Col md={2}></Col>
            <Col>
              <EnglishMsgUI />
            </Col>
          </Row>
        )}
        <FormGroupInput
          label={`作曲者名${getValues('ivtType') !== 2 ? '*' : ''}`}
          labelMd="2"
          colMd="10"
          value={valuesForm?.composerName}
          register={register('composerName')}
          onChange={(value) => {
            handleChangeInput('composerName', () =>
              setValuesForm({ ...valuesForm, composerName: value }),
            );
            setValue('composerName', value);
          }}
          isReview={isReview}
          classForm={`form-with-label-nowrap ${
            errors.composerName?.message === SuggestMsg ? 'mb-0' : ''
          }`}
          errorMessage={errors.composerName?.message}
          maxLength={MaxLength.composerName}
        />
        {errors.composerName?.message === SuggestMsg && (
          <Row className="mb-3">
            <Col md={2}></Col>
            <Col>
              <KanjiMsgUI />
            </Col>
          </Row>
        )}
        <FormGroupInput
          isReview={isReview}
          label="作曲者名(フリガナ)"
          labelMd="2"
          colMd="10"
          value={valuesForm?.composerFuriganaName}
          register={register('composerFuriganaName')}
          onChange={(value) => {
            handleChangeInput('composerFuriganaName', () =>
              setValuesForm({ ...valuesForm, composerFuriganaName: value }),
            );
            setValue('composerFuriganaName', value);
          }}
          classForm={`form-with-label-nowrap ${
            errors.composerFuriganaName?.message === SuggestMsg ? 'mb-0' : ''
          }`}
          errorMessage={errors.composerFuriganaName?.message}
          maxLength={MaxLength.composerFuriganaName}
        />
        {errors.composerFuriganaName?.message === SuggestMsg && (
          <Row className="mb-3">
            <Col md={2}></Col>
            <Col>
              <KatakanaMsgUI />
            </Col>
          </Row>
        )}
        <FormGroupInput
          isReview={isReview}
          label="作曲者名(英語表記)"
          labelMd="2"
          colMd="10"
          value={valuesForm?.composerEnglishName}
          register={register('composerEnglishName')}
          onChange={(value) => {
            handleChangeInput('composerEnglishName', () =>
              setValuesForm({ ...valuesForm, composerEnglishName: value }),
            );
            setValue('composerEnglishName', value);
          }}
          classForm={`form-with-label-nowrap ${
            errors.composerEnglishName?.message === SuggestMsg ? 'mb-0' : ''
          }`}
          errorMessage={errors.composerEnglishName?.message}
          maxLength={MaxLength.composerEnglishName}
        />
        {errors.composerEnglishName?.message === SuggestMsg && (
          <Row className="mb-3">
            <Col md={2}></Col>
            <Col>
              <EnglishMsgUI />
            </Col>
          </Row>
        )}
        <FormGroupInput
          label="編曲者名"
          labelMd="2"
          colMd="10"
          value={valuesForm?.arrangerName}
          register={register('arrangerName')}
          isReview={isReview}
          onChange={(value) => {
            handleChangeInput('arrangerName', () =>
              setValuesForm({ ...valuesForm, arrangerName: value }),
            );
            setValue('arrangerName', value);
          }}
          classForm={`form-with-label-nowrap ${
            errors.arrangerName?.message === SuggestMsg ? 'mb-0' : ''
          }`}
          errorMessage={errors.arrangerName?.message}
          maxLength={MaxLength.arrangerName}
        />
        {errors.arrangerName?.message === SuggestMsg && (
          <Row className="mb-3">
            <Col md={2}></Col>
            <Col>
              <KanjiMsgUI />
            </Col>
          </Row>
        )}
        <FormGroupInput
          label="編曲者名(フリガナ)"
          labelMd="2"
          colMd="10"
          value={valuesForm?.arrangerFuriganaName}
          register={register('arrangerFuriganaName')}
          onChange={(value) => {
            handleChangeInput('arrangerFuriganaName', () =>
              setValuesForm({ ...valuesForm, arrangerFuriganaName: value }),
            );
            setValue('arrangerFuriganaName', value);
          }}
          isReview={isReview}
          classForm={`form-with-label-nowrap ${
            errors.arrangerFuriganaName?.message === SuggestMsg ? 'mb-0' : ''
          }`}
          errorMessage={errors.arrangerFuriganaName?.message}
          maxLength={MaxLength.arrangerFuriganaName}
        />
        {errors.arrangerFuriganaName?.message === SuggestMsg && (
          <Row className="mb-3">
            <Col md={2}></Col>
            <Col>
              <KatakanaMsgUI />
            </Col>
          </Row>
        )}
        <FormGroupInput
          label="編曲者名(英語表記)"
          labelMd="2"
          colMd="10"
          value={valuesForm?.arrangerEnglishName}
          register={register('arrangerEnglishName')}
          onChange={(value) => {
            handleChangeInput('arrangerEnglishName', () =>
              setValuesForm({ ...valuesForm, arrangerEnglishName: value }),
            );
            setValue('arrangerEnglishName', value);
          }}
          isReview={isReview}
          classForm={`form-with-label-nowrap ${
            errors.arrangerEnglishName?.message === SuggestMsg ? 'mb-0' : ''
          }`}
          errorMessage={errors.arrangerEnglishName?.message}
          maxLength={MaxLength.arrangerEnglishName}
        />
        {errors.arrangerEnglishName?.message === SuggestMsg && (
          <Row className="mb-3">
            <Col md={2}></Col>
            <Col>
              <EnglishMsgUI />
            </Col>
          </Row>
        )}
        <FormInputNumber
          name="showTime"
          pureType={false}
          onChange={(time) => handleChangeInput('showTime', () => setValue('showTime', time))}
          label="演奏時間*"
          labelMd={2}
          colMd={4}
          placeholder="HH:MM:SS"
          format="##:##:##"
          value={getValues().showTime}
          errorMessage={errors.showTime?.message}
          isReview={isVideo ? isReview : true}
          hintText={!isVideo ? '音源データを登録すると自動反映されます' : ''}
        />
        <Controller
          name="versionIdentificationType"
          control={control}
          render={({ field }) => (
            <GroupCheckBox
              id="version-indent"
              labelForm="原盤識別*"
              name="versionIndent"
              options={versionIdentificationType}
              labelMd="2"
              colMd="10"
              onChange={(value) => {
                setVersionIdentificationType(value);
                const valueChecked = value.filter((o) => o.checked)[0];
                field.onChange(valueChecked.value);
              }}
              isReview={isReview}
              classForm="form-with-label-nowrap mb-3"
              errorMessage={errors.versionIdentificationType?.message}
            />
          )}
        />

        <Controller
          name="liveType"
          control={control}
          render={({ field }) => (
            <GroupCheckBox
              id="live-class"
              labelForm="ライブ区分*"
              name="liveType"
              options={liveType}
              labelMd="2"
              colMd="10"
              onChange={(value) => {
                setLiveType(value);
                const valueChecked = value.filter((o) => o.checked === true)[0];
                field.onChange(valueChecked.value);
              }}
              isReview={isReview}
              classForm="form-with-label-nowrap mb-3"
              errorMessage={errors.liveType?.message}
            />
          )}
        />
        <Form.Group as={Row} className="mb-3 form-with-label-nowrap">
          <Form.Label column md="2">
            {variation?.includeJasracContent !== INCLUDE_JARSAC_CONTENT.INCLUDE
              ? '著作権信託先'
              : labelRequire('著作権信託先*')}
          </Form.Label>
          <Col md="10">
            <Row className="d-flex w-100 h-100 align-items-center m-0">
              <Col md="12" className="p-0">
                <Row>
                  <Col md="6" className="px-0 d-flex align-items-center">
                    <Controller
                      name="authorizedGroupType"
                      control={control}
                      render={({ field }) => (
                        <GroupCheckBox
                          id="copyright-entrustment"
                          options={authorizedGroupType}
                          colMd="12"
                          colClass="px-0"
                          onChange={(value) => {
                            setAuthorizedGroupType(value);
                            const valueChecked = value.filter((o) => o.checked)[0];
                            field.onChange(valueChecked.value);
                            setValue('jasracCode', '');
                            setValue('otherJasracCode', '');
                            setValuesForm({ ...valuesForm, jasracCode: '', otherJasracCode: '' });
                          }}
                          classForm="w-100"
                          classOption="flex-1"
                          errorMessage={errors.authorizedGroupType?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col md="6" className="px-0">
                    <FormControl
                      {...register('authorizedGroup')}
                      disabled={!isEnableCopyRight}
                      maxLength={MAX_INPUT}
                    />
                    <MessageError message={errors.authorizedGroup?.message} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Form.Group>
        <FormGroupInput
          label="ISRC番号"
          labelMd="2"
          colMd="4"
          placeholder="JPA123456789"
          value={valuesForm?.isrcCode}
          register={register('isrcCode')}
          onChange={(value) => {
            handleChangeInput('isrcCode', () => setValuesForm({ ...valuesForm, isrcCode: value }));
            setValue('isrcCode', value);
          }}
          isReview={isReview}
          errorMessage={errors.isrcCode?.message}
          maxLength={12}
        />
        <FormGroupInput
          label={'JASRAC作品コード'}
          labelMd="2"
          colMd="4"
          value={getValues().jasracCode}
          register={register('jasracCode')}
          isReview={getValues('authorizedGroupType') !== 1}
          onChange={(value) => onChangeCodeJasrac(value)}
          classForm="form-with-label-nowrap"
          errorMessage={errors.jasracCode?.message}
          placeholder="000-0000-0"
          maxLength={10}
          classInput="pe-auto"
        />
        <FormGroupInput
          label={'JASRAC以外の作品コード'}
          labelMd="2"
          colMd="4"
          value={valuesForm?.otherJasracCode}
          register={register('otherJasracCode')}
          isReview={!includes([2, 3, 4], getValues('authorizedGroupType'))}
          onChange={(value) =>
            handleChangeInput('otherJasracCode', () => {
              setValuesForm({ ...valuesForm, otherJasracCode: value }),
                setValue('otherJasracCode', value);
            })
          }
          classForm="form-with-label-nowrap pe-auto"
          errorMessage={errors.otherJasracCode?.message}
          hintText="[著作権信託先]にNexToneを選んだ時は必須"
          maxLength={9}
        />
        {renderInformationFee()}
        <Controller
          name="lyricsClassifyType"
          control={control}
          render={({ field }) => (
            <GroupCheckBox
              id="translate-class"
              labelForm={`原詞訳詞区分${
                getValues('ivtType') === 1 || getValues('ivtType') === 2 ? '*' : ''
              }`}
              name="lyricsClassifyType"
              options={lyricsClassifyType}
              labelMd="2"
              colMd="10"
              onChange={(value) => {
                setLyricsClassifyType(value);
                const valueChecked = value.filter((o) => o.checked)[0];
                field.onChange(valueChecked.value);
              }}
              isReview={isReview}
              classForm="form-with-label-nowrap mb-3"
              errorMessage={errors.lyricsClassifyType?.message}
            />
          )}
        />
        {!isVideo && (
          <>
            <Form.Group as={Row} className="mb-1">
              <Form.Label column md="2">
                {labelRequire('音源データ*')}
              </Form.Label>
              <Col md="10">
                <div className="d-flex align-items-center pe-auto mb-1">
                  {!isReview && (
                    <Form.Group controlId="formFile" className="me-3">
                      <Button
                        variant="primary"
                        onClick={() => uploadSongRef && uploadSongRef.current.click()}
                        style={{ minWidth: '130px' }}
                      >
                        ファイルを選択
                      </Button>
                      <input
                        ref={uploadSongRef}
                        className="hidden-file"
                        type="file"
                        onChange={onChangeSong}
                        accept={audioTypes.join(',')}
                      />
                    </Form.Group>
                  )}
                  {songUrl && (
                    <>
                      <p className="ps-1 text-truncate w-85">{getValues('fileName')}</p>
                    </>
                  )}
                </div>
                {songUrl && (
                  <>
                    <AudioCustom
                      classAudio="mt-4 mb-2"
                      ref={audioPlayer}
                      audioPlayer={audioPlayer}
                      onLoadedMeta={onLoadedMetadata}
                      src={songUrl}
                      onTimeAudioUpdate={(e: any) => {
                        if (isPreview) {
                          setCurrentTimeSong({
                            ...currentTimeSong,
                            0: e?.target?.currentTime + 0.05 || 0,
                          });
                        }
                      }}
                      isPlayManual={isPreview}
                      onPrimaryClick={() => setIsPreview(false)}
                      onTimeDrag={() => {
                        if (onSelectRow >= 0) {
                          lyricRef.current[onSelectRow]?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'center',
                          });
                        }
                      }}
                      onEnded={() => {
                        setIsPreview(false);
                        setOnSelectRow(-1);
                        setCurrentTimeSong({});
                        lyricRef.current[0]?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start',
                          inline: 'center',
                        });
                      }}
                    />
                  </>
                )}
                <MessageError message={errors.sourceAudioUrl?.message} />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className={`mb-3 ${isAfterDownload(variation) && 'pe-none user-select-none'}`}
            >
              <Form.Label column md="2">
                {labelRequire('歌詞テキスト')}
              </Form.Label>

              <Col md="9">
                <div className="d-flex flex-row align-items-end flex-wrap">
                  <label className={`${isDiabled ? 'd-none' : 'd-contents'}`}>
                    <input
                      className="hidden-file"
                      type="file"
                      onChange={(e) => renderTxt(e)}
                      accept=".txt"
                    />
                    <span className="btn btn-primary" style={{ minWidth: '130px' }}>
                      ファイルを選択
                    </span>
                  </label>
                  <ButtonGroup className={`ms-2 ${isDiabled && 'd-none'}`}>
                    <Select
                      value={songSelected}
                      options={optionSongs}
                      noOptionsMessage={() => '登録されている楽曲がありません'}
                      className="w-300 placeholder-text-center"
                      placeholder={
                        <div className="text-center gray"> 登録済みの楽曲の歌詞をコピーして</div>
                      }
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        option: (styles: any) => ({
                          ...styles,
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          paddingLeft: '6px',
                          paddingRight: '6px',
                        }),
                      }}
                      backspaceRemovesValue
                      isClearable
                      onChange={(value) => setSongSelected(value)}
                      onMenuOpen={() => setDisableEnter(true)}
                      onMenuClose={() => setDisableEnter(false)}
                      filterOption={(option, inputValue) => {
                        const { label } = option;
                        return `${label}`.toUpperCase().includes(inputValue.toUpperCase());
                      }}
                      onInputChange={(text: string, action: InputActionMeta) => {
                        if (action.action === 'input-change') {
                          setSongSelected({
                            value: songSelected?.value || '',
                            label: text || '',
                          });
                        }
                      }}
                    />
                    <Button
                      className="flex-0 btn-not-allowed"
                      disabled={!songSelected}
                      onClick={() => {
                        handlePasteLyric();
                        if (songSelected && songSelected.lyrics === '[]') {
                          setValue('lyricInformationFee', '');
                        }
                      }}
                    >
                      歌詞を登録する
                    </Button>
                  </ButtonGroup>
                  <div
                    className={`mt-3 ms-2 ${(lineLyric.length > 0 && songUrl) || 'd-none'} ${
                      isDiabled && 'd-none'
                    } ${isCountDownBtn && 'pe-none'}`}
                  >
                    <ButtonCountDown
                      handleClick={() => onHandleRecordSongTime()}
                      title="音源を再生して歌詞タイミングをレコーディング"
                      seconds={isPaused ? 3 : 0}
                      isCountDown={(value) => setIsCountDownBtn(value)}
                    />
                    <Button
                      className="ms-2 btn-focus-none"
                      onClick={() => {
                        setIsPreview(!isPreview);
                      }}
                    >
                      {isPreview ? '一時停止して編集する' : 'プレビュー再生'}
                    </Button>
                  </div>
                </div>

                {Array.isArray(lineLyric) && (
                  <div id="table-lyrics" className="overflow-auto pe-auto mt-3 lyric-wrapper">
                    <table className="table-lyrics mt-3">
                      {lineLyric?.map((item: any, index: number) => {
                        return (
                          <div
                            ref={(el) => (lyricRef.current[index] = el)}
                            key={index}
                            className={`${onSelectRow === index ? 'border border-primary' : ''} ${
                              isContentLyrics(item) || isPreview ? 'pe-none' : ''
                            }`}
                          >
                            <tr
                              onClick={() => setOnSelectRow(index)}
                              className={`${
                                isAfterDownload(variation) && 'pe-none user-select-none'
                              }}`}
                            >
                              <td
                                className={`w-15 px-2 py-0 ${
                                  onSelectRow === index ? 'item-choose2' : ''
                                }`}
                              >
                                <NumberFormat
                                  value={item?.time}
                                  onValueChange={({ formattedValue }) => {
                                    const lineLyricClone = [...lineLyric];
                                    lineLyricClone[index].time = formattedValue;
                                    setLineLyric(lineLyricClone);
                                  }}
                                  className="form-control rounded position-relative border-0 bg-transparent p-0"
                                  format="##:##:###"
                                />
                              </td>
                              <td
                                className={`px-2 py-0 ${
                                  onSelectRow === index ? 'item-choose2' : ''
                                }`}
                              >
                                <Form.Control
                                  value={item?.content}
                                  onChange={(e) => {
                                    const lineLyricClone = [...lineLyric];
                                    lineLyricClone[index].content = e.target.value;
                                    setLineLyric(lineLyricClone);
                                  }}
                                  type="text"
                                  className="border-0 bg-transparent p-0"
                                  maxLength={MAX_INPUT}
                                />
                              </td>
                            </tr>
                          </div>
                        );
                      })}
                    </table>
                  </div>
                )}

                <Col className={`mt-3  ${isDiabled && 'd-none'} ${isCountDownBtn && 'pe-none'}`}>
                  <ButtonCountDown
                    handleClick={() => onHandleRecordSongTime()}
                    title="音源を再生して歌詞タイミングをレコーディング"
                    seconds={isPaused ? 3 : 0}
                    isCountDown={(value) => setIsCountDownBtn(value)}
                    disabled={isDisableBtn()}
                    className="btn-not-allowed"
                  />

                  <Button
                    variant="primary ms-2"
                    onClick={() => onHandleEnter()}
                    disabled={isDisableBtn() || isPreview}
                    className="btn-not-allowed"
                  >
                    ENTER
                  </Button>
                  <Button
                    variant="primary ms-5"
                    onClick={() => setSpeed(0.5)}
                    disabled={isDisableBtn()}
                    className="btn-not-allowed"
                  >
                    再生速度 0.5
                  </Button>
                  <Button
                    variant="primary ms-2"
                    onClick={() => setSpeed(1.0)}
                    disabled={isDisableBtn()}
                    className="btn-not-allowed"
                  >
                    再生速度 1.0
                  </Button>
                  <Button
                    variant="primary ms-2"
                    onClick={() => setSpeed(1.25)}
                    disabled={isDisableBtn()}
                    className="btn-not-allowed"
                  >
                    再生速度 1.25
                  </Button>
                </Col>
                <div className={`fs-11 mt-2 ${isDiabled && 'd-none'}`}>
                  <p>1.音源データをアップロードします</p>
                  <p>2.歌詞テキストファイルをアップロードします</p>
                  <p>
                    3.レコーディングボタンを押して歌詞の行の切り替えタイミングを記録を開始します
                  </p>
                  <p>4.歌詞の行の切り替えタイミングをEnterキーを押して記録します</p>
                </div>
              </Col>
            </Form.Group>
          </>
        )}
      </Form>
      <div className="d-flex justify-content-end txt-require">ご注意：＊印は必須項目です。</div>
    </>
  );
};
export default SongInformation;
